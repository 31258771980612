.WrapperTable {
  border: 1px solid #eeeeee;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
}

.Table {
  border-radius: 8px;
  /* width: auto;  */
  /* max-width: 70%; */
  table-layout: auto;
  border-collapse: collapse;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06); */
  overflow: hidden; /* This helps maintain the border radius with collapse */
  /* border: 1px solid #eeeeee; */
}

.Table th {
  background-color: var(--kemu-color-primary);
  font-weight: bold;
  color: #eeeeee;
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
}

.Table th, .Table td {
  border: 1px solid #eeeeee;
  padding: 14px;
  text-align: left;
}

/* Remove border doubling by removing redundant borders */
.Table tr th {
  border-top: none;
}

.Table tr:last-child td {
  border-bottom: none;
}

.Table tr td:first-child,
.Table tr th:first-child {
  border-left: none;
}

.Table tr td:last-child,
.Table tr th:last-child {
  border-right: none;
}

/* Add back the outer border for the whole table */
.Table {
  border: 1px solid #eeeeee;
}

/* Base custom block for aligned-block directive */
.CustomBlock {
  /* margin: 1.5rem 0;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

/* Width classes */
.width40 {
  width: 40%;
}

.width50 {
  width: 50%;
}

.width60 {
  width: 60%;
}

.width70 {
  width: 70%;
}

.width80 {
  width: 80%;
}

.width90 {
  width: 90%;
}

.width100 {
  width: 100%;
}

/* Alignment classes */
.alignLeft {
  margin-left: 0;
  margin-right: auto;
  text-align: left;
}

.alignCenter {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.alignRight {
  margin-left: auto;
  margin-right: 0;
  text-align: right;
}